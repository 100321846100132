<template>
	<div>
		
	</div>
</template>

<script>
	export default{
		
		data(){
			return {
				
			}
		},
		created(){
			let token = this.$route.query.token ? this.$route.query.token : '';
			if(token != ''){
				localStorage.setItem('account-token',token);
				this.$router.push('/mendian');
			}else{
				this.$message.error('登录失败');
			}
		},
		methods:{
			
		},
	}
</script>

<style>
</style>